import { defineComponent } from 'vue'

import DsView from '@/components/DsView'
import { Model } from '@/components/DsForm'
import { Column } from '@/components/DsTable'

const supplierModel: Model = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      label: 'Name',
      required: true,
    },
  },
}

const columns: Column[] = [{ key: 'name', header: 'Name' }]

const defaultClient = () => ({
  name: '',
})

export default defineComponent({
  name: 'Fournisseurs',
  components: { DsView },
  setup() {
    return () => (
      <DsView
        view={{
          collection: 'suppliers',
          title: 'Suppliers',
          model: supplierModel,
          columns: columns,
          createButtonLabel: 'New supplier',
          rowKey: 'name',
        }}
        defaultValue={defaultClient}
      />
    )
  },
})
